// Your variable overrides
$purple:#7D36D2;
$primary:$purple;//hsl(214, 94%, 43%);//hsl(210, 75%, 35%);//rgb(100, 0, 244);hsl(210, 76%, 43%);
$gray-900: #0D0C0E;// #1C1A1E;
$list-group-bg: none;
//$body-bg: $gray-900;//$white;
//$primary: $dark;
//$body-bg: rgba(0, 0, 0, 0);
$link-decoration: none;
$form-range-track-height: 1.0rem;
$form-range-thumb-width: 1.5rem;

// Removes the bottom border in modal headers
$modal-header-border-width: 0px;

$toast-background-color: none;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// There seems to be a bug in bootstrap-icons v1.10.3 that fails to 
// because this $bootstrap-icons-font variable is in quotation marks in the bootstrap-icons.scss file
$bootstrap-icons-font: bootstrap-icons;
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import '~bootstrap/scss/bootstrap.scss';

// Required
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

@font-face{
    font-family: "Gilroy-Regular";
    src: url('/fonts/gilroy/Gilroy-Regular.ttf');
}
@font-face{
    font-family: "Gilroy-Light";
    src: url('/fonts/gilroy/Gilroy-Light.ttf');
}
@font-face{
    font-family: "Gilroy-Medium";
    src: url('/fonts/gilroy/Gilroy-Medium.ttf');
}
@font-face{
    font-family: "Gilroy-Bold";
    src: url('/fonts/gilroy/Gilroy-Bold.ttf')
}
@font-face{
    font-family: "Gilroy-Extrabold";
    src: url('/fonts/gilroy/Gilroy-Extrabold.ttf')
}
@font-face{
    font-family: "Race-Sport";
    src: url('/fonts/racesport/RaceSport-BWPLV.ttf')
}

body{
    font-size: 1.6rem;
    color:#363636;
    font-family: "Gilroy-Regular" !important;
}

@include media-breakpoint-down(md) {
    body{
        font-size: 1.4rem;
    }
}
.spinner-border{
    width: 1.2rem;
    height:1.2rem;
    vertical-align: -0.25em;
}
.bg-dark a{
    color:#fff;
}

.bg-dark .nav-link{
    color: #fff;
    //border-color: #fff !important;
}

.bg-dark .card{
    background: #1C1A1E;
    border-color: #1C1A1E;
}
.leagueposition{
    background: #CF268A;
    color: #0D0C0E;
    line-height:'1.2rem';
}
.leagueposition1{
    background: #FFCA2B;
    color: #0D0C0E;
    line-height:'1.2rem';
}

.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate{
    white-space: nowrap;
    overflow: hidden;
}

.footer{
    background: #1C1A1E;
}
.prefooter{
    background:#4C81E9;
}
.textcolor-lime {
    color: #B6E238;
}

.getready h1{
    font-family: 'Race-Sport';
}

.teamlogostrip {
    min-height: 200px;
    background-image: url(./homeimages/teamlogostrip.png) ; 
    background-repeat: repeat-x;
    background-position-y: center;
    background-size: 80%;
}

.teamlogostrip-light{
    background: #F8F8F8;
}

.teamlogostrip-dark{
    background: #1C1A1E;
}

.beginchallenge {
    min-height: 200px;
    background-image: url(./homeimages/beginchallenge.jpg);
    background-repeat: no-repeat;
    background-position-y: top;
    background-size: cover;
}

.redeempartners h1{
    font-family: 'Race-Sport';
}

.settlescores h1{
    font-family: 'Race-Sport';
}
.howitworks h1{
    font-family: 'Race-Sport';
}
.banterwithfriends{
    font-size: 2.0rem;
    font-weight: bold;
}
.heroesleague h1{
    font-family: 'Race-Sport';
}
.shareandwintitle{
    background-image:url(./homeimages/bluepatchestr.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-position-y: top;
    background-position-x: 3rem;
    padding-top: 8rem;
    font-family: 'Race-Sport';
    
}

.fantastakelogo{
    width:150px;
}
.noarrow .dropdown-toggle::after {
    display: none !important; 
}

@include media-breakpoint-up(lg){
    .fantastakelogo{
        width:200px;
    }
    .howitworks h1{
        font-family: 'Race-Sport';
        font-size: 3.0rem;
    }
    .heroesleague h1{
        font-family: 'Race-Sport';
        font-size: 3.0rem;
    }
    .shareandwintitle h1{
        font-family: 'Race-Sport';
        font-size: 3.0rem;
    }
    .settlescores h1{
        font-family: 'Race-Sport';
        font-size: 3.0rem;
    }
    .redeempartners h1{
        font-family: 'Race-Sport';
        font-size: 3.0rem;
    }
    .teamlogostrip {
        min-height: 240px;
        background-size: 40%;
    }
    .getready h1{
        font-family: 'Race-Sport';
        font-size: 3.0rem;
    }

}

