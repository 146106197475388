body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}  
}

/* @keyframes duration | easing-function | delay |
iteration-count | direction | fill-mode | play-state | name */
.wink {
  animation: 1s linear 0s infinite alternate fadeIn;
  -webkit-animation: 1s linear 0s infinite alternate fadeIn;
  -moz-animation: 1s linear 0s infinite alternate fadeIn;
  -o-animation: 1s linear 0s infinite alternate fadeIn;
  -ms-animation: 1s linear 0s infinite alternate fadeIn;
}

.fade-in{
  animation: fadeIn 1s linear 1 forwards;
  -webkit-animation: fadeIn 1s linear 1 forwards;
  -moz-animation: fadeIn 1s linear 1 forwards;
  -o-animation: fadeIn 1s linear 1 forwards;
  -ms-animation: fadeIn 1s linear 1 forwards;
}